import { DateRange } from 'models/date-range'
import { AnalyticsProjectFilters } from 'models/hubtype-analytics'
import { BadgeRefresh } from '../services/gateways/analytics.gateway'

export interface InsightsState {
  readonly dateFrom: DateRange
  readonly projectFilters: AnalyticsProjectFilters
  readonly projectBadges: BadgeRefresh
  readonly queuesBadges: BadgeRefresh
  readonly providersBabges: BadgeRefresh
  readonly agentsPerformanceEnabled: boolean
  readonly agentsPerformanceShowBanner: boolean
}

export const insightsInitialState: InsightsState = Object.freeze({
  dateFrom: null,
  projectFilters: null,
  projectBadges: null,
  queuesBadges: null,
  providersBabges: null,
  agentsPerformanceEnabled: true,
  agentsPerformanceShowBanner: true,
})
