export const LOCALES_REGEX =
  '^(af|ak|sq|am|ar|hy|as|az|bm|eu|be|bn|bs|bg|my|ca|zh|kw|hr|cs|da|nl|en|eo|et|ee|fo|fi|fr|ff|gl|lg|ka|de|el|gu|ha|he|hi|hu|is|ig|id|ga|it|ja|kl|kn|kk|km|ki|rw|ko|lv|lt|mk|mg|ms|ml|mt|gv|mr|ne|nd|nb|nn|or|om|ps|fa|pl|pt|pa|ro|rm|ru|sg|sr|sn|ii|si|sk|sl|so|es|sw|sv|ta|te|th|bo|ti|to|tr|uk|ur|uz|vi|cy|yo|zu)$'

export const WHATSAPP_NAMESPACE_LENGTH = 36

export const NOT_IDENTICAL_PASSWORDS =
  'New password is not identical to the confirmation password!'
export const EMPTY_FORM_FIELDS = 'There are required fields empty!'
export const PASSWORD_MATCHES_USER_INFO =
  'New password cannot be the same as your username or email!'
export const PASSWORD_CHANGED = 'The password has been changed!' // pragma: allowlist secret
export const PASSWORD_EXTERNAL_ERROR =
  'There was an external error changing the password'
export const NEWPASSWORD_MATCHES_CURRENTPASSWORD =
  'New password cannot be the same as your current password!'

export const DEFAULT_LANGUAGE = 'en'

export const guideTemplateText: string[] = [
  `To add variables use {{1}} for the first one, {{2}} for the second, and so on.`,
  `Example: Hi {{1}}, this should be done by {{2}}.`,
  `Agents will fill these variables every time they use this template.`,
]

export const RIGHT_SIDE_VERTICAL_PADDING = 12
export const TABLE_FOOTER_HEIGHT = 78 - RIGHT_SIDE_VERTICAL_PADDING

export const SCREEN_XL = 1920

export enum HubtypeAIUrl {
  KnowledgeBase = '/ai/knowledge-base',
  IntentModels = '/ai/intentmodels',
}
