export enum SentryTags {
  HUBTYPE_API_ERROR = 'hubtypeApiError',
  HUBTYPE_API_ERROR_CODE = 'hubtypeApiErrorCode',
  INVALID_CASE_MESSAGE = 'invalidCaseMessage',
  INVALID_FLOWBUILDER_MESSAGE = 'invalidFlowbuilderMessage',
  INVALID_FLOWBUILDER_ORIGIN = 'invalidFlowbuilderOrigin',
}

export enum SentryFingerprints {
  HTTP_ERROR = 'HTTP Error',
  FLOW_BUILDER_ORIGIN_ERROR = 'FlowBuilderOriginError',
  INVALID_CASE_MESSAGE = 'InvalidCaseMessage',
}
