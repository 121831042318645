// @ts-nocheck
// Store
import {
  FacebookLoginProvider,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login'
import { AgmCoreModule } from '@agm/core'
import { APP_BASE_HREF } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatNativeDateModule } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu'
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Router, RouteReuseStrategy } from '@angular/router'
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive' // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { EffectsModule } from '@ngrx/effects'
import { MetaReducer, StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import * as Sentry from '@sentry/angular'
import { FacebookModule } from 'ngx-facebook'
import { LinkyModule } from 'ngx-linky'
import { MomentModule } from 'ngx-moment' // optional, provides moment-style pipes for date formatting
import { BaseModule } from 'shared/base/base.module'
import { SharedComponentModule } from 'shared/shared-components.module'
// App & Modules
import { environment } from '../environments/environment'
import { initializeUserGuiding } from './app-initializers'
import { AppMaterialModule } from './app-material.module'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthModule } from './auth/auth.module'
import { CustomRouteReuseStrategy } from './customRouteReuseStrategy'
// Effects
import { CaseEffects } from './desk/services/case.effects'
import { ProviderIntegrationModule } from './provider-integration/provider-integration.module'
import { localStorageSyncReducer, reducers } from './reducers/reducers'
// Services
import { AuthGuard } from './services/auth-guard.service'
import { ColorSchemeService } from './services/color-scheme.service'
import { QueueEffects } from './services/effects/queue.effects'
import { StatsEffects } from './services/effects/stats.effects'
import { UserEffects } from './services/effects/user.effects'
import { FeedbackService } from './services/feedback.service'
import { HubtypeApiProviders } from './services/hubtype-api'
import { HubtypeAuthInterceptor } from './services/hubtype-api/hubtype-auth.interceptor'
import { SegmentInterceptor } from './services/hubtype-api/segment.interceptor'
import {
  AdminRoleGuard,
  ManagerOrAdminRoleGuard,
} from './services/role-guard.service'
import { TaskExecutorService } from './services/task-executor.service'
import { SharedBrandModule } from './shared/shared-brand/shared-brand.module'
import { SplashComponent } from './splash/splash.component'

declare global {
  interface Window {
    analytics: any
  }
}

const authConfig = [
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.facebook_app_id, {
      version: 'v20.0',
    }),
  },
]

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer]

@NgModule({
  declarations: [AppComponent, SplashComponent],
  imports: [
    AppRoutingModule,
    AppMaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    AuthModule,
    ProviderIntegrationModule,
    HttpClientModule,
    LinkyModule,
    MatNativeDateModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    SharedBrandModule,
    SharedComponentModule,
    AgmCoreModule.forRoot({
      apiKey: environment.maps_api,
    }),
    FacebookModule.forRoot(),
    EffectsModule.forRoot([
      CaseEffects, // TODO: Move to forFeature eventually
      QueueEffects,
      StatsEffects,
      UserEffects,
    ]),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    BaseModule,
  ],

  providers: [
    ...HubtypeApiProviders,
    ColorSchemeService,
    AuthGuard,
    AdminRoleGuard,
    ManagerOrAdminRoleGuard,
    FeedbackService,
    TaskExecutorService,
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: authConfig,
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HubtypeAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SegmentInterceptor,
      multi: true,
    },

    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeUserGuiding,
      deps: [],
      multi: true,
    },
    ...(environment.sentryURL
      ? [
          {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false,
            }),
          },
          {
            provide: Sentry.TraceService,
            deps: [Router],
          },
          {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
          },
        ]
      : []),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
