import { Type } from '@angular/core'
import {
  JsonConvert,
  JsonConverter,
  JsonCustomConvert,
  ValueCheckingMode,
} from 'json2typescript'

/**
 * Type used to clarify that an object has the same fields as T (useful for IDEs and linters)
 * but it's not actually an instance of T, but a serialization of T
 */
export type JsonFor<T> = T

const jsonParser = new JsonConvert()
jsonParser.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

export function assertDeserialize<T>(object: any, expected: Type<T>): T | null {
  if (!object) {
    return null
  } else if (object.constructor.name === expected.name) {
    return object
  }
  return jsonParser.deserializeObject(object, expected)
}

export function deserialise<T>(object: any, expected: Type<T>): T {
  return jsonParser.deserializeObject(object, expected)
}

export function deserializeArray<T>(arr: any[], expected: Type<T>): T[] {
  const values = arr?.length ? arr : []
  return jsonParser.deserializeArray(values, expected)
}

export function serialise(data: any): any {
  return jsonParser.serializeObject(data)
}

export function isObject(value: unknown): boolean {
  return value === Object(value)
}

export function cloneValue<T>(value: T): T {
  return JSON.parse(JSON.stringify(value))
}

@JsonConverter
export class DateConverter implements JsonCustomConvert<Date> {
  serialize(date: Date): any {
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate() +
      ';' +
      date.getHours() +
      ':' +
      date.getMinutes()
    )
  }

  deserialize(date: any): Date {
    return new Date(date)
  }
}
