import {
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { containsNormalizedText } from 'utils/string-utils'

export interface DropdownData {
  value: string
  id: string
}

export interface MultisectionDropdownData {
  title?: string
  items: DropdownData[]
}

export enum HT_DROPDOWN_SIZE {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  TINY = 'tiny',
}

@Component({
  selector: 'ht-dropdown',
  templateUrl: './ht-dropdown.component.html',
  styleUrls: ['./ht-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => HtDropdownComponent),
    },
  ],
})
export class HtDropdownComponent
  implements ControlValueAccessor, OnInit, OnChanges
{
  @ViewChild('dropdownBtn') dropdown
  @Input()
  data: MultisectionDropdownData[] | DropdownData[] = []
  @Input()
  disabled = false
  @Input()
  placeholder?: string
  @Input()
  searchBarPlaceholder?: string
  @Input()
  testId?: string
  @Input()
  label?: string
  @Input() fullWidth = false

  @HostBinding('class') @Input() size = HT_DROPDOWN_SIZE.MEDIUM
  @HostBinding('class.is-full-width') get isFullWidth() {
    return this.fullWidth
  }

  filteredData: any[]
  selectedId: string
  selectedValue: string
  isDropdownOpen = false
  isFocus = false
  dropdownMenuWidth = 'auto'
  searchValue = ''
  isMultiSection = false

  private onTouched!: () => void
  private onChanged!: (value) => void

  constructor(private _eref: ElementRef) {}

  //On click outside of component
  @HostListener('document:click', ['$event'])
  hideDropdown(event) {
    if (
      this.isDropdownOpen &&
      !this._eref.nativeElement.contains(event.target)
    ) {
      this.onCloseDropdown()
    }
  }

  ngOnInit(): void {
    this.filteredData = this.data
    this.isMultiSection = Boolean(
      (this.data?.[0] as MultisectionDropdownData)?.items
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.filteredData = this.data
      this.setValue()
    }
  }

  registerOnChange(onChange: any) {
    this.onChanged = onChange

    //Call on init
    if (this.selectedId) {
      this.selectedId = this.selectedId
    }
  }
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  onSelect(id: string) {
    this.onTouched()
    this.selectedId = id
    this.onChanged(id)
    this.setValue()
    this.onCloseDropdown()
  }

  toggleDropdown() {
    this.setDropdownListSize()
    if (this.isDropdownOpen) {
      this.onCloseDropdown()
    } else {
      this.isDropdownOpen = this.isFocus = true
    }
  }

  setDropdownListSize() {
    this.dropdownMenuWidth = this.dropdown.nativeElement.offsetWidth
  }

  writeValue(obj: any): void {
    this.selectedId = obj
    this.setValue()
  }

  setValue() {
    let flattenedData: DropdownData[] = []
    if (this.isMultiSection) {
      const multisectionData = this.data as MultisectionDropdownData[]
      flattenedData = multisectionData.reduce(
        (prev, next) => prev.concat(next.items),
        []
      )
    } else {
      flattenedData = this.data as DropdownData[]
    }
    this.selectedValue = flattenedData?.find(
      item => item.id === this.selectedId
    )?.value
  }

  onCloseDropdown() {
    this.filteredData = this.data
    this.isDropdownOpen = false
    this.isFocus = false
    this.searchValue = ''
  }

  filterItems(value: string) {
    if (!value) {
      this.filteredData = this.data
      return
    }
    this.filteredData = []
    if (this.isMultiSection) {
      this.filterMultiSectionItems(value)
    } else {
      this.filterUniSectionItems(value)
    }
  }

  private filterMultiSectionItems(value: string) {
    const filteredMultiSectionData: MultisectionDropdownData[] = []
    const multiSectionData = this.data as MultisectionDropdownData[]
    multiSectionData.forEach(section => {
      if (containsNormalizedText(section.title, value)) {
        filteredMultiSectionData.push(section)
      } else {
        const newSection = {
          title: section.title,
          items: section.items.filter(i =>
            containsNormalizedText(i.value, value)
          ),
        }
        if (newSection.items.length > 0) {
          filteredMultiSectionData.push(newSection)
        }
      }
    })
    this.filteredData = filteredMultiSectionData
  }

  private filterUniSectionItems(value: string) {
    let filteredUniSectionData = this.data as DropdownData[]
    filteredUniSectionData = filteredUniSectionData.filter(i =>
      containsNormalizedText(i.value, value)
    )
    this.filteredData = filteredUniSectionData
  }
}
