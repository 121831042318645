/* eslint-disable @typescript-eslint/naming-convention */
import { HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
// import { HubtypeCasePaginator } from './case-search.service.dtos'
import { HubtypeRelatedCase } from 'models/hubtype-related-case'
import { HubtypeRealtimeCase } from 'models/hubtype-slas'
import moment from 'moment'
import { EMPTY, Observable, of } from 'rxjs'
import { catchError, first, map, switchMap } from 'rxjs/operators'
import { TransferCaseData } from 'shared/entities/transfer-data'
import { arrayRemoveDuplicatesByKey } from 'utils/array-utils'
import { assertDeserialize } from 'utils/json-utils'
import * as deskAction from '../../actions/desk'
import {
  AddDraftMessageAction,
  SelectArchiveCaseAction,
  SelectInboxCaseAction,
} from '../../actions/desk'
import {
  CaseSyncDataList,
  DownLoadableCaseExtension,
  HubtypeCase,
} from '../../models/hubtype-case'
import { HubtypeMessage } from '../../models/hubtype-message'
import { HubtypeCaseCounter } from '../../models/hubtype-types'
import * as fromRoot from '../../reducers'
import { CaseList } from '../../reducers/desk.state'
import { Utilities } from '../../shared/utilities'
import { ConverterService } from '../converter.service'
import { CustomQueryEncoderHelper } from './auth.service'
import { ExportStatus } from './case.service.dtos'
import { HubtypeApiService } from './hubtype-api.service'

export interface CasesPagination {
  cases: HubtypeCase[]
  hasMore: boolean
  caseList: CaseList
}
@Injectable()
export class CaseService {
  constructor(
    protected store: Store<fromRoot.State>,
    @Inject('apiService') protected apiService: HubtypeApiService,
    @Inject('Utilities') protected utilities: Utilities,
    @Inject('convertService') protected convertService: ConverterService
  ) {}

  getSelectedCase(readOnly = false): Observable<HubtypeCase> {
    const caze = readOnly
      ? this.store
          .select(fromRoot.getArchiveSelectedCase)
          .pipe(map(c => c && c.case))
      : this.store.select(fromRoot.getSelectedCase)

    return caze.pipe(map(c => assertDeserialize(c, HubtypeCase)))
  }

  setSelectedCase(caseId: string, readOnly = false): Observable<HubtypeCase> {
    return this.getCase(caseId).pipe(
      first(),
      map(caze => {
        const action = readOnly
          ? new SelectArchiveCaseAction(caze)
          : new SelectInboxCaseAction(caze)
        this.store.dispatch(action)
        return caze
      })
    )
  }

  unselectCase(readOnly = false): void {
    const action = readOnly
      ? new SelectArchiveCaseAction(null)
      : new SelectInboxCaseAction(null)
    this.store.dispatch(action)
  }

  getSelectedCaseMessages(readOnly = false): Observable<HubtypeMessage[]> {
    return readOnly
      ? this.store.select(fromRoot.getArchiveCaseMessages)
      : this.store.select(fromRoot.getSelectedCaseMessages)
  }

  // /cases/<id>/
  getCase(caseId: string, forceSync = false): Observable<HubtypeCase> {
    if (forceSync) {
      return this.fetchCase(caseId)
    }
    return this.store.select(fromRoot.getCase(caseId)).pipe(
      switchMap(htCase => {
        if (!htCase) {
          console.log('Case not found in state, it will be fetched')
          return this.fetchCase(caseId)
        }
        return of(htCase)
      })
    )
  }

  getStoredCase(caseId): Observable<HubtypeCase> {
    return this.store.select(fromRoot.getCase(caseId))
  }
  // /cases/<id>/reopen/
  reopenCase(caseId): Observable<HubtypeCase> {
    const path = `/cases/${caseId}/reopen/`
    return this.apiService
      .post(path, null, null, 'v2')
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response.case,
            HubtypeCase
          )
        )
      )
  }

  // /cases/counters/
  getCounters(): Observable<HubtypeCaseCounter> {
    const path = `/cases/counters/`
    return this.apiService.get(path, null, 'v2')
  }

  /**
   * It assigns the given case to the given agent
   *
   * @param caseId The id of the case to assign
   * @param agentId The id of the agent to be assigned to
   */
  assignCaseTo(caseId: string, agentId: string): Observable<HubtypeCase> {
    const path = `/cases/${caseId}/assign_to/`
    return this.apiService
      .post(path, { agent_id: agentId }, null, 'v2', false)
      .pipe(
        map(response => {
          const updated = this.convertService.jsonConvert.deserializeObject(
            response.case,
            HubtypeCase
          )
          this.store.dispatch(new deskAction.AssignCaseMeAction(updated))
          return updated
        })
      )
  }

  unassignCaseDispatch(caseId): Observable<HubtypeCase> {
    const path = `/cases/${caseId}/unassign/`
    return this.apiService.post(path, null, null, 'v2').pipe(
      map(response => {
        const c = this.convertService.jsonConvert.deserializeObject(
          response.case,
          HubtypeCase
        )
        this.store.dispatch(new deskAction.UnassignCaseAction(c as HubtypeCase))
        return c
      })
    )
  }

  // POST /cases/<id>/transfer/?queue=<id>
  transferCase(
    caseId: string,
    transferData: TransferCaseData
  ): Observable<HubtypeCase> {
    const path = `/cases/${caseId}/transfer/`
    const body = {
      queue_id: transferData.queueId,
      user_id: transferData.userId,
    }
    return this.apiService
      .post(path, body, null, 'v2')
      .pipe(
        map<any, HubtypeCase>(response =>
          this.convertService.jsonConvert.deserializeObject(
            response.case,
            HubtypeCase
          )
        )
      )
  }

  // POST /cases/<id>/resolve/
  resolveCase(caseId, result): Observable<HubtypeCase> {
    const path = `/cases/${caseId}/resolve/`
    const body = { resolution_status: result.result, type: result.typification }
    return this.apiService
      .post(path, body, null, 'v2')
      .pipe(
        map<any, HubtypeCase>(response =>
          this.convertService.jsonConvert.deserializeObject(
            response.case,
            HubtypeCase
          )
        )
      )
  }

  resolveCaseOk(caseId, typification): Observable<HubtypeCase> {
    return this.resolveCase(caseId, {
      result: 'result_ok',
      typification,
    }).pipe(first())
  }

  discardCase(caseId): Observable<HubtypeCase> {
    const data = {
      result: HubtypeCase.RESOLUTION_DISCARD,
      typification: HubtypeCase.TYPIFICATION_DISCARD,
    }
    return this.resolveCase(caseId, data).pipe(first())
  }

  addContactReasons(
    caseId: string,
    contactReasonsIds: string[]
  ): Observable<HubtypeCase> {
    const path = `/cases/${caseId}/add_contact_reasons/`
    const body = { contact_reasons: contactReasonsIds }
    return this.apiService.post(path, body, null, 'v2').pipe(
      first(),
      map(response =>
        this.convertService.jsonConvert.deserializeObject(
          response.case,
          HubtypeCase
        )
      )
    )
  }
  removeContactReasons(
    caseId: string,
    contactReasonsIds: string[]
  ): Observable<HubtypeCase> {
    const path = `/cases/${caseId}/remove_contact_reasons/`
    const body = { contact_reasons: contactReasonsIds }
    return this.apiService.post(path, body, null, 'v2').pipe(
      first(),
      map(response =>
        this.convertService.jsonConvert.deserializeObject(
          response.case,
          HubtypeCase
        )
      )
    )
  }

  // POST /messages/
  addMessage(
    caseId: string,
    message: HubtypeMessage,
    templateId?: string
  ): Observable<HubtypeMessage> {
    const fd = new FormData()
    if (message.image) {
      const img_data = this.utilities.dataURLToBlob(message.image)
      const img_name =
        caseId + Math.random() + '.' + img_data.type.substr('image/'.length)
      fd.append('image', img_data, img_name)
    }

    if (message.document) {
      const doc_data = message.document
      const doc_name = caseId + Math.random() + message.file_info.extension
      fd.append('document', doc_data, doc_name)
    }
    fd.append('text', message.text)
    fd.append('case_id', caseId)
    fd.append('action', message.action)
    if (templateId) {
      fd.append('template_id', templateId)
    }
    if (message.whatsapp_template_message) {
      fd.append(
        'whatsapp_template_message',
        JSON.stringify(message.whatsapp_template_message)
      )
    }
    if (message.file_info) {
      fd.append('file_info', JSON.stringify(message.file_info))
    }
    if (message.raw) {
      fd.append('raw', JSON.stringify(message.raw))
    }
    return this.apiService
      .post('/messages/', fd, null)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response.message,
            HubtypeMessage
          )
        )
      )
  }

  // Wrapper of addMessage
  sendMessage(
    htCase: HubtypeCase,
    message: HubtypeMessage,
    templateId: string = null
  ): Observable<HubtypeMessage> {
    this.store.dispatch(new AddDraftMessageAction(message))
    return this.addMessage(htCase.id, message, templateId)
  }

  // POST /cases/<id>/typing_indicator/
  sendStartTyping(caseId): Observable<void> {
    const path = `/cases/${caseId}/typing_indicator/`
    const body = { typing_action: 'start' }
    return this.apiService.post(path, body, null, 'v2')
  }

  // POST /cases/<id>/typing_indicator/
  sendStopTyping(caseId): Observable<void> {
    const path = `/cases/${caseId}/typing_indicator/`
    const body = { typing_action: 'stop' }
    return this.apiService.post(path, body, null, 'v2')
  }

  // GET /cases/<id>/export/
  download(
    hubtypeCase: HubtypeCase,
    extension: DownLoadableCaseExtension = DownLoadableCaseExtension.TXT
  ): Observable<string> {
    let path = null
    let params = null
    let responseType = null
    if (extension === DownLoadableCaseExtension.PDF) {
      path = `/cases/${hubtypeCase.id}/transcript/`
      responseType = 'blob'
    } else {
      path = `/cases/${hubtypeCase.id}/export/`
      params = { extension }
    }
    return this.apiService.get(path, params, 'v2', false, responseType)
  }

  // POST /cases/<id>/set_description/
  saveDescription(caseId, description): Observable<HubtypeCase> {
    const path = `/cases/${caseId}/set_description/`
    const body = { description }
    return this.apiService
      .post(path, body, null, 'v2')
      .pipe(
        map<any, HubtypeCase>(response =>
          this.convertService.jsonConvert.deserializeObject(
            response.case,
            HubtypeCase
          )
        )
      )
  }

  saveDescriptionDispatch(caseId, description): Observable<HubtypeCase> {
    return this.saveDescription(caseId, description).pipe(
      first(),
      map(c => {
        this.store.dispatch(
          new deskAction.UpdateCaseDescription(c as HubtypeCase)
        )
        return c
      })
    )
  }

  // GET /cases/export_status
  exportStatus(exportId): Observable<ExportStatus> {
    const path = '/cases/export_status/'
    const params: HttpParams = new HttpParams({
      fromObject: {
        export_id: exportId,
      },
      encoder: new CustomQueryEncoderHelper(),
    })
    return this.apiService.get(path, params, 'v2')
  }

  // GET /slas/today
  getTodaySLAs(): Observable<HubtypeRealtimeCase[]> {
    const path = '/slas/today/'
    const params = {
      start_date: moment().startOf('day').toISOString(true),
    }
    return this.apiService
      .getAllPaginatedResults(100, path, 'v2', params)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeArray(
            arrayRemoveDuplicatesByKey(response, 'case_id'),
            HubtypeRealtimeCase
          )
        )
      )
  }

  // POST /cases/new_conversation/
  createNewConversation(
    providerAccountId: string,
    recipientNumber: string,
    queueId: string
  ): Observable<HubtypeCase> {
    const path = '/cases/new_conversation/'
    const params: HttpParams = new HttpParams({
      fromObject: {
        provider_account_id: providerAccountId,
        recipient: recipientNumber,
        queue_id: queueId,
      },
      encoder: new CustomQueryEncoderHelper(),
    })
    return this.apiService
      .post(path, null, params, 'v2')
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response.case,
            HubtypeCase
          )
        )
      )
  }

  fetchCases(
    page: number,
    caseList: CaseList,
    pageSize: number,
    order: string
  ): Observable<{
    data: CasesPagination
    next: Observable<CasesPagination>
    total: number
  }> {
    const path = `/cases/`
    const params: HttpParams = new HttpParams({
      fromObject: {
        case_list: caseList,
        order,
        page_size: pageSize.toString(),
        page: page.toString(),
      },
      encoder: new CustomQueryEncoderHelper(),
    })
    return this.apiService.get(path, params, 'v2', false).pipe(
      first(),
      map(
        (response: {
          count: number
          next: string
          previous: string
          results: []
        }) => {
          let next = null
          if (response.next !== null) {
            next = this.fetchCases(page + 1, caseList, pageSize, order)
          }

          return {
            data: {
              cases: this.convertService.jsonConvert.deserializeArray(
                response.results,
                HubtypeCase
              ),
              hasMore: Boolean(response.next),
              caseList,
            },
            next,
            total: response.count,
          }
        }
      ),
      catchError(err => {
        console.error(`Could not fetch inbox cases`, err)
        return EMPTY
      })
    )
  }

  loadCasesInParallel(caseType: CaseList) {
    if (caseType === CaseList.WAITING) {
      this.store.dispatch(new deskAction.UpdateWaitingCasesCountAction(1))
    }
    this.getCasesListParallelLoading(caseType)
      .pipe(first())
      .subscribe(resp => {
        this.dispatchCases(caseType, resp.first)
        resp.next.forEach(next => {
          if (caseType === CaseList.WAITING) {
            this.store.dispatch(new deskAction.UpdateWaitingCasesCountAction(1))
          }
          next.pipe(first()).subscribe(data => {
            this.dispatchCases(caseType, data.data.cases)
          })
        })
      })
  }

  getRelatedCases(
    chatId: string,
    toDate: string
  ): Observable<HubtypeRelatedCase[]> {
    const params = { chat_id: chatId, to_datetime: toDate }
    const path = `/cases/related_chat_cases_info/`
    return this.apiService.get(path, params, 'v2', true).pipe(
      first(),
      map(cases =>
        this.convertService.jsonConvert.deserializeArray(
          cases,
          HubtypeRelatedCase
        )
      )
    )
  }

  getBackendAgentCasesStatus(): Observable<CaseSyncDataList> {
    const path = `/cases/current_status/`
    return this.apiService.get(path, null, 'v2', true).pipe(
      first(),
      map(caseStatus =>
        this.convertService.jsonConvert.deserializeObject(
          caseStatus,
          CaseSyncDataList
        )
      )
    )
  }

  markAsTranslatable(caseId): Observable<{ case: HubtypeCase }> {
    const path = `/cases/${caseId}/mark_as_translatable/`
    return this.apiService.post(path, {}, null, 'v2').pipe(first())
  }

  private getCasesListParallelLoading(caseType: CaseList) {
    const pageSize = 100
    return this.fetchCases(1, caseType, pageSize, 'created_at').pipe(
      first(),
      map(resp => {
        const $obs: Observable<{
          data: CasesPagination
          next: Observable<CasesPagination>
          total: number
        }>[] = []
        const partition = resp.total / pageSize
        for (let i = 1; i < partition && partition >= 1; i++) {
          $obs.push(
            this.fetchCases(i + 1, caseType, pageSize, 'created_at').pipe(
              first()
            )
          )
        }
        return {
          first: resp.data.cases,
          next: $obs,
        }
      })
    )
  }

  private dispatchCases(caseType: CaseList, caseList: HubtypeCase[]) {
    if (caseType === CaseList.WAITING) {
      this.store.dispatch(new deskAction.UpdateCasesWaitingListAction(caseList))
    }
    if (caseType === CaseList.IDLE) {
      this.store.dispatch(new deskAction.UpdateCasesIdleListAction(caseList))
    }
    if (caseType === CaseList.ATTENDING) {
      this.store.dispatch(
        new deskAction.UpdateCasesAttendingListAction(caseList)
      )
    }
  }

  public updateEndUserTyping(caseId: string, isTyping: boolean) {
    this.store.dispatch(
      new deskAction.UpdateCaseEnduserTypingStatus({ caseId, isTyping })
    )
  }

  public closeTicketByEnduser(caseId: string) {
    this.store.dispatch(new deskAction.CloseCaseByEndUser(caseId))
  }

  // /cases/<id>/
  private fetchCase(caseId: string): Observable<HubtypeCase> {
    const path = `/cases/${caseId}/`
    return this.apiService.get(path, null, 'v2').pipe(
      map(response =>
        this.convertService.jsonConvert.deserializeObject(response, HubtypeCase)
      ),
      catchError(err => {
        console.error(`Could not fetch the Case ${caseId}`, err)
        return EMPTY
      })
    )
  }
}
