import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as Sentry from '@sentry/angular'
import { SentryFingerprints, SentryTags } from '../constants/sentry'

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor() {}

  logHttpError(requestMethod: string, responseError: HttpErrorResponse): void {
    this.logRegularError(
      'HTTP Error',
      `Error making a ${requestMethod} request to ${responseError?.url}`,
      {
        fingerprint: [
          requestMethod,
          responseError?.url,
          String(responseError?.status),
        ],
        tags: {
          [SentryTags.HUBTYPE_API_ERROR]: true,
          [SentryTags.HUBTYPE_API_ERROR_CODE]: responseError?.status,
        },
        extra: {
          RequestMethod: requestMethod,
          HttpErrorResponse: responseError,
        },
      }
    )
  }

  logRegularError(
    errorName: string,
    errorMessage: string,
    additionalInfo?: {
      fingerprint?: SentryFingerprints[] | string[]
      tags?: { [key in SentryTags]?: any }
      extra?: { [key: string]: any }
    }
  ): void {
    const error = new Error(errorMessage)
    error.name = errorName

    const { fingerprint, tags, extra } = additionalInfo || {}

    Sentry.withScope(scope => {
      if (fingerprint) scope.setFingerprint(fingerprint)

      let sentryHint
      if (tags || extra) {
        sentryHint = { ...(tags && tags), ...(extra && extra) }
      }

      Sentry.captureException(error, sentryHint)
    })
  }
}
