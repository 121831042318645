import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { FeatureFlag } from '../constants/feature-flags'
import * as fromRoot from '../reducers'

@Injectable({
  providedIn: 'root',
})
export class CampaignsGuard implements CanActivate {
  constructor(private store: Store<fromRoot.State>) {}

  canActivate(): Observable<boolean> {
    return this.store.select(fromRoot.getOrganization).pipe(
      filter(x => Boolean(x)),
      map(org => {
        if (!org || !org.feature_flags_json) {
          return false
        }
        // IMPORTANT: org not deserialized to HubtypeOrganization on Guard due to early stage
        //  => needs manual check (not method on class) of campaigns feature
        return Boolean(org.feature_flags_json[FeatureFlag.CAMPAIGNS])
      })
    )
  }
}
